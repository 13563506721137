<template>
    <div class="home public_wrap">
        <topnav active="home"></topnav>
        <topmenu active="home"></topmenu>

        <div class="public_width">
            <div class="home_cate">
                <div class="home_carousel">
                    <div v-if="banner.length > 0">
                        <el-carousel height="396px" ref="swiper" trigger="click" :interval="2500" :initial-index="0" >    
                            <el-carousel-item class="carouselItem" v-for="item in banner" :key="item.id">
                            <a :href="item.link_url" target="_blank" v-if="item.link_url">
                                <img :src="item.thumb"/>
                            </a>
                            <img :src="item.thumb" v-else/>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>

            <div class="home_coupon d-flex">
                <div class="tag">
                    <div class="less">领券立减</div>
                    <p class="font">已有 <span>{{coupon_count || 0}}</span> 人领取</p>
                </div>

                <div class="coupon_carousel d-flex">
                    <!-- <i class="prev iconfont icon-bg-left" @click="prev()"></i> -->
                    <el-carousel ref="sale"  class="carousel_list" trigger="click" height="155px" :initial-index="0" :autoplay="false" :loop="false" v-if="coupon_list">
                        <div class="carousel_wrap">
                            <el-carousel-item v-for="item in coupon_list" :key="item">
                                <div class="carousel_box d-center" :class="[v.use_target == '会员' ? 'vip':'',v.check_allow_get && v.check_allow_get.allow_get ? '':'already']" v-for="(v) in item" :key="v">
                                    <div class="carousel_num">
                                        <!-- <i class="flag iconfont icon-VIP" v-if="v.use_target == '会员'"></i> -->
                                        <div v-if="v.use_target == '会员'">
                                            <img v-if="v.check_allow_get && v.check_allow_get.allow_get"  class="flag" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-141824.png"/>
                                            <img v-else class="flag" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-141819.png"/>
                                        </div>
                                        <div class="name">{{v.name}}</div>
                                        <div class="money d-center"> ￥<span>{{v.dec_money}}</span></div>
                                        <div class="cond">满{{v.threshold_money}}可用</div>
                                        <div class="button" @click="rob(v)" v-if="v.check_allow_get && v.check_allow_get.allow_get">立即抢券</div>
                                        <div class="button" v-else>已领取</div>
                                    </div>
                                </div>
                            </el-carousel-item>
                        </div>
                    </el-carousel>
                    <!-- <i class="next iconfont icon-bg-right" @click="next()"></i> -->
                </div>
            </div>

            <div class="home_product d-flex">
                <div class="home_goods">
                    <div class="home_title d-flex"><img class="goods_icon" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%96%B0%E5%88%87%E9%A2%9812.16/rmyp.png">热门样品</div>
                    <ul>
                        <li class="item" v-for="(item) in goods" :key="item.id" @click="to_detail(item)">
                            <img class="img" :src="item.thumbs && item.thumbs[0] ? item.thumbs[0]: ''" alt="">
                            <div class="name">{{item.name}}</div>
                            <div class="desc d-between"><span>样品数量：{{item.stock}}</span><span>购买人数：{{ item.order_details_count }}</span></div>
                        </li>
                    </ul>
                </div>
                <div class="home_order" v-if="0">
                    <div class="home_title d-flex"><img class="order_icon" src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E6%96%B0%E5%88%87%E9%A2%9812.16/cjdd.png">成交订单</div>
                    <div class="order_table">
                        <ul class="order_title d-between">
                            <li class="left">用户昵称</li>
                            <li>订单编号</li>
                            <li>下单金额</li>
                            <!-- <li class="d-center">赠送E币</li> -->
                        </ul>
                        <div class="order_content">
                            <!-- <el-carousel height="450px" trigger="click" arrow="never" direction="vertical">    
                                <el-carousel-item v-for="(item,index) in order_list" :key="index">
                                    <div class="order_box d-flex" v-for="(v) in item" :key="v">
                                        <ul class="order_item d-between">
                                            <li>{{v.user && v.user.nickname ? v.user.nickname : ''}}</li>
                                            <li>{{v.serial_number}}</li>
                                            <li class="money">￥{{v.total_money_real || 0}}</li>
                                            <li class="blue">{{v.reward_ebc || 0}}</li> 
                                        </ul>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>  -->

                            <vue3-seamless-scroll :list="order" class="seamless-warp" :step="0.5">
                                <div class="order_list">    
                                    <div class="order_box d-flex" v-for="(v) in order" :key="v" >
                                        <ul class="order_item d-between">
                                            <li class="left">{{v.nick}}</li>
                                            <li class="d-center">{{v.number}}</li>
                                            <li>￥{{v.total_money_real || 0}}</li>
                                            <!-- <li class="blue">{{v.reward_ebc || 0}}</li>  -->
                                        </ul>
                                    </div>
                                </div> 
                            </vue3-seamless-scroll>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <foot />
        <toolbar />


        <el-dialog
            custom-class="modal_plus"
            v-model="show"
            >
            <div class="cou_content">
                <i class="iconfont icon-zhuyi"></i>
                <p class="cou_title">该券仅平台会员可用</p>
                <div class="cou_txt">
                    <p>请先前往易百纳技术社区充值会员后</p>
                    <p>再进入芯城领券下单</p>
                </div>
                <div class="cou_btn">
                    <button @click="show = false">取消</button>
                    <button class="to" @click="to_plus()">开通PLUS</button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import topnav from "@/components/Nav.vue";
import topmenu from "@/components/Menu.vue";
import toolbar from "@/components/Toolbar.vue";
import { getData, postData } from "@/api/user";
import foot from "@/components/Footer.vue";
import { reactive,toRefs,ref,onMounted,nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ElMessage } from 'element-plus'
import { Vue3SeamlessScroll } from "vue3-seamless-scroll";



export default {
    name: "Home",
    components: {
        topnav,
        topmenu,
        foot,
        toolbar,
        Vue3SeamlessScroll

    },
    setup() {
        const store = useStore();
        const router = useRouter();
        const swiper = ref(null)
        const sale= ref(null);
        store.commit('setCount');
        
        let state = reactive({
            userid: store.state.userId,
            banner: [],
            goods: [],
            coupon: [],
            coupon_count:0,
            coupon_list:[],
            coupon_idx:0,
            order: [],
            order_list:[],
            order_idx:0,
            show: false,
            ebaina_charge_url:'',
        })

        let init = () => {
            getData({
                url: `api/home/index`,
                data: {},
            }).then((res) => {
                let data = res.data;
                if (data.code === 200) {
                    // banner
                    // state.banner = data.data.banner.concat([{thumb:'https://ebaina.oss-cn-hangzhou.aliyuncs.com/ic/20211225-161847.jpeg'}])
                    state.banner = data.data.banner

                    // coupon 
                    state.coupon_count = data.data.coupon_count
                    state.coupon = data.data.coupons
                    state.coupon.forEach((v,k) =>{
                        if(k == 0 ||  k % 5 != 0) {
                            if(!state.coupon_list[state.coupon_idx]) {
                                state.coupon_list.push([v])
                            }else {
                                state.coupon_list[state.coupon_idx].push(v)
                            }
                        }else {
                            state.coupon_idx++
                            state.coupon_list.push([v])
                        }
                    })

                    // goods 
                    state.goods = data.data.hot_goods

                    // order
                    state.order = data.data.order
                    state.order.forEach((v) =>{
                        v.number = v.serial_number ? v.serial_number.substr(0,2)+'***'+ v.serial_number.substr(v.serial_number.split('').length-3,v.serial_number.split('').length) : ''
                        if(v.user && v.user.nickname) {
                            if(v.user.nickname.length > 5) {
                                v.nick = v.user.nickname.substr(0,2)+'***'+ v.user.nickname.substr(v.user.nickname.split('').length-2,v.user.nickname.split('').length)
                            }else {
                                v.nick = v.user.nickname
                            }
                        }else {
                            v.nick = ''
                        }
                    })

                    // 社区路由
                    state.ebaina_charge_url = data.data.ebaina_charge_url
                    let urls = {
                        ebaina_ebc_url: data.data.ebaina_ebc_url,
                        ebaina_url: data.data.ebaina_url,
                        ebaina_charge_url: data.data.ebaina_charge_url
                    }
                    
                    store.commit('setUrl',urls)

                }else {
                    ElMessage.error(data.data.msg);
                }
            });
        }
        init()


        let prev =  () => {
            sale.value.prev()
        }

        let next = () => {
            sale.value.next()
        }

        let to_detail = (v) => {
            router.push({
                path: '/detail',
                query:{
                    id: v.id
                }
            })
            window.scrollTo(100,0)
        }

        let to_product = (item,v) => {
            if( v && v.name !='') { // 二级菜单
                router.push({
                    path: '/product',
                    query:{
                        first:item.name,
                        second: v.name,
                        first_id: item.id,
                        second_id: v.id
                    }
                })
            }else {
                router.push({
                    path: '/product',
                    query:{
                        first:item.name,
                        first_id: item.id
                    }
                })
            }
            window.scrollTo(100,0)
        }

        let rob = (v) => {
            if(state.userid) {
                postData({
                    url: `api/home/get_coupon`,
                    data: {
                        coupon_id:v.id
                    },
                }).then((res) => {
                    let data = res.data
                    if(data.code == 200) {
                        v.check_allow_get.allow_get = false
                        ElMessage.success(data.data.msg)
                    }else {
                        if(data.data.use_target_need && data.data.use_target_need == '会员') {
                            state.show = true
                        } else {
                            ElMessage.error(data.data.msg)
                        }
                    }
                });
            }else {
                store.commit("setLoginBox", true);
            }
        }

        let to_plus = () => {
            if(state.userid) {
                window.open(state.ebaina_charge_url,'_blank','')
            }else {
                store.commit("setLoginBox", true);
            }
        }
        
        onMounted(()=>{
            nextTick(()=>{
                let timer = setInterval(function () {
                    // swiper.value.next()
                    // sale.value.setActiveItem(0)
                    clearInterval(timer)
                }, 500)
            })
        })

        return {
            sale,
            swiper,
            ...toRefs(state),
            prev,
            next,
            to_detail,
            to_product,
            to_plus,
            rob,
        };
    },
};




</script>

<style lang="less" scoped>
.home {
    padding: 154px 0 440px;

    .home_cate {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        padding-left:252px;
    }

    .home_carousel {
        position: relative;
        flex: 1;
        width: 100%;
        height: 396px;
        margin: 0;
        background: #fff;

        a {
            cursor: pointer;
        }

        img {
            width: 100%;
            height: 100% !important;
            object-fit: contain;
        }

        /deep/.el-carousel__indicators--horizontal {
            position: absolute;
            left: auto;
            right: 10px;
            bottom: 10px;
            text-align: right;

            .el-carousel__indicator--horizontal button {
                width: 8px;
                height: 8px;
                background: #ffffff;
                border-radius: 50%;
                opacity: 0.5;
            }

            .el-carousel__indicator--horizontal.is-active button {
                width: 24px;
                height: 8px;
                background: #ffffff;
                opacity: 0.5;
                border-radius: 10px;
            }
        }

        /deep/ .el-carousel__item {
            display: flex;
        }

        /deep/ .el-carousel__item:first-child {
            display: flex!important;
        }
    }


    .home_coupon {
        position: relative;
        margin-bottom: 20px;
        height: 155px;
        background: #FFFFFF;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        padding-left: 240px;

        .tag {
            position: absolute;
            left: 0;
            top:0;
            height: 100%;
            width: 242px;
            z-index: 996;
            background: url(https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-144902.png) no-repeat center center;
            padding: 40px 45px 40px 25px;

            .less {
                font-size: 22px;
                font-weight: 500;
                line-height: 30px;
                color: #FFF;
                text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.16);
                padding-bottom: 15px;
                border-bottom: 2px dashed #fff;
                margin-bottom: 13px;
            }
            
            .font {
                font-size: 12px;
                font-weight: 600;
                line-height: 17px;
                color: #FFF;
                opacity: 0.73;
            }
        }

        .prev,.next {
            font-size: 30px;
            left: 0;
            position:absolute;
            cursor: pointer;
            color: rgba(94, 92, 92, 0.3);
            z-index:998;
        }
        .next {
            left: auto;
            right: 10px;
        }
        .coupon_carousel {
            position: relative;
            flex: 1;
            align-items: center;

            .carousel_list {
                flex: 1;
                // padding: 0 40px;
            }
            
            .carousel_wrap {
                display: flex;
                align-items: center;
                height: 100%;
                width: 100%;
            }

            /deep/ .el-carousel__indicators {
                display: none;
            }

            /deep/ .el-carousel__item {
                display: flex;
                align-items: center;
                width: 100%;
            }


            /deep/ .el-carousel__item:first-child {
                display: flex!important;
            }

            .line {
                width: 0;
                height: 98px;
                border-right: 1px solid #E3E3E3;
            }

            

            .carousel_box {
                position:relative;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                width:220px;
                margin: 0 1px;
                background: #559BFB;

                .flag {
                    position: absolute;
                    top: 0;
                    left: 0;
                    font-size: 22px;
                    color: #fff;
                }

                .carousel_num {
                    height: 100%;
                    width: 100%;
                    padding: 0 24px 0 30px;
                    text-align:center;
                    .name {
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 21px;
                        color: #FFFFFF;
                        margin: 15px 0  4px;
                        overflow: hidden;
                        white-space:nowrap;
                        text-overflow: ellipsis;
                    }
                    .money {
                        display: flex;
                        align-items: center;
                        font-size: 20px;
                        font-weight: 600;
                        color: #fff;
                        line-height: 42px;
                        span {
                            font-size: 30px;
                        }
                    }
                    .cond {
                        font-size: 13px;
                        font-weight: 400;
                        line-height: 18px;
                        color: #fff;
                        margin-bottom: 7px;
                    }
                    .button {
                        width: 96px;
                        height: 31px;
                        border: 1px solid #fff;
                        border-radius: 16px;
                        outline: none;
                        background: transparent;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 31px;
                        color: #fff;
                        cursor: pointer;
                        margin: 0 auto;
                    }
                }
                
            }

            .carousel_box.already {
                background: rgba(85, 155, 251, .5);
                opacity: 50;
                .button {
                    cursor: default;
                }
            }

            .carousel_box.vip {
                background: linear-gradient(180deg, #EFC195 0%, #B47B4F 100%);
                color: #fff;

                .carousel_num {
                    .money {
                        color: #fff;
                    }
                    .cond {
                        color: #fff;
                    }
                    .button {
                        border: 1px solid #fff;
                        color: #fff;
                    }
                }
            }

            .carousel_box.vip.already {
                background:linear-gradient(180deg, #F0D9C3 0%, #D4B7A0 100%);
                opacity: 50;
                .button {
                    cursor: default;
                }
            }
        
        }
    }

    .home_product {
        margin-bottom: 50px;

        .home_title {
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            line-height: 21px;
            color: #333;
            margin-bottom: 18px;
            .goods_icon {
                width: 23px;
                height: 27px;
                margin-right: 5px;
            }
            .order_icon {
                width: 26px;
                height: 22px;
                margin-right: 5px;
            }
        }
    
        .home_goods{
            --n: 5;
            flex: 1;
            background: #FFFFFF;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
            padding: 24px;

            .item {
                cursor: pointer;
                display: inline-block;
                width: 217px;
                background: #FFFFFF;
                border: 1px solid #F4F4F4;
                opacity: 1;
                border-radius: 8px;
                padding: 18px 20px 26px;
                margin:0 calc((1302 - var(--n) * 217) / var(--n) / 2 * 1px) 20px;
            }

            .item:hover {
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            }

            .img {
                display: block;
                height: 107px;
                width: 107px;
                object-fit: contain;
                margin:0 auto;
            }

            .name {
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #333;
                margin-top: 20px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .desc {
                font-size: 12px;
                font-weight: 400;
                line-height: 17px;
                color: #666666;
                margin-top: 17px;
            }
        }

        .home_order{
            width: 380px;
            margin-left: 24px;
            background: #FFFFFF;
            box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
            padding: 24px 0;
            .home_title {
                padding: 0 24px;
            }
            
            .order_title {
                border-bottom: 1px solid #EDEDED;
                padding: 0 24px;
                li {
                    width: 29%;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #333;
                    padding-bottom: 16px;
                    text-align: center;
                }
                li.left {
                    text-align: left;
                }
            }
            .order_item {
                width: 100%;
                height: 40px;
                line-height: 40px;
                padding: 0 24px;
                li {
                    width: 29%;
                    font-size: 13px;
                    font-weight: 400;
                    line-height: 18px;
                    color: #666;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    text-align: center;
                    
                }
                li.blue {
                    color: #1C46B7;
                    font-weight: 600;
                    text-align: center;
                }
                li.left {
                    text-align: left;
                }
            }

            .order_content {
                height: 502px;
                overflow: hidden;
            }

            .seamless-warp {
                overflow: hidden;
            }
            
            .order_box:nth-child(even) {
                background: #F8F8F8;
            }
        }
    }

}

</style>


<style lang="less">
.modal_plus {
  width: 340px!important;
  height: 279px!important;
  background: #FFFFFF;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  opacity: 1;
  border-radius: 4px;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  margin: 0;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0!important;
  }

  .cou_content {
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0;

    .iconfont {
      font-size: 68px;
      color:#FF9025;
      margin-bottom: 23px;
    }

    .cou_title {
      font-size: 16px;
      font-weight: 900;
      line-height: 22px;
      color: #333333;
      margin-bottom: 10px;
    }
    .cou_txt {
      font-size: 13px;
      line-height: 18px;
      color: #666666;
      margin-bottom: 23px;
      text-align: center;
    }
    .cou_btn {
      button {
        width: 68px;
        height: 32px;
        background: #FAFAFA;
        border: 1px solid #DDDDDD;
        border-radius: 3px;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin-right: 10px;
        background: #fff;
      }
      button.to {
        width: 88px;
        background: #0064F9;
        color: #fff;
        border: none;
      }
    }
  }
}
</style>






